import React from "react"
import { Link } from "gatsby"

import Layout from "../layouts/pt"
import SEO from "../components/SEO"
import Metomic from "../components/Metomic"
import SplashScreens from "../components/SplashScreens"
import Header from "../components/Header.pt"

const PrivacyPolicyPage = props => (
  <Layout location={props.location}>
    <Metomic />
    <SEO
      title="Política de Privacidade | Jubarte Design"
      locale="pt"
      altLocale="en"
      pathname="/privacy-policy/"
      description="Confira nossa Política de Privacidade para entender como protegemos suas informações e os dados que coletamos."
    />
    <SplashScreens />
    <Header showLanguageSelector />
    <div className="position-relative text-center u-space-3-top">
      <div className="container u-space-3-top u-space-2-top--md u-space-0-bottom">
        <div className="w-md-80 w-lg-60 text-center mx-auto form-search-wrapper">
          <h1 className="display-4 font-size-48--md-down jd-h2-titles">
            Política de <span className="font-weight-bold">Privacidade</span>
          </h1>
        </div>
      </div>
    </div>
    <article className="jd-terms">
      <div className="container u-space-1-top">
        <div className="w-lg-90 mx-auto">
          <div className="mb-4">
            <span className="text-muted">Data Efetiva: 08 de Junho de 2020</span>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Introdução</h2>
            <p>
              Bem-vindo à <b>Jubarte Design</b>.
            </p>
            <p>
              A Jubarte Design ("<b>nós</b>" ou "<b>nosso</b>") opera{" "}
              <a href="https://jubarte.design" rel="nofollow" className="jd-terms-link">
                https://jubarte.design
              </a>{" "}
              (a partir de agora referido como "<b>Serviço</b>"). Estamos empenhados em proteger sua privacidade e em
              cumprir as leis de proteção de dados aplicáveis. Esta Política de Privacidade descreve como nós coletamos,
              usamos, divulgamos e salvaguardamos informações sobre você. Nós também descrevemos as escolhas que você
              tem sobre suas informações.
            </p>
            <p>
              Nossa Política de Privacidade rege suas visitas ao website{" "}
              <a href="https://jubarte.design" rel="nofollow" className="jd-terms-link">
                https://jubarte.design
              </a>{" "}
              , todos os seus subdomínios e subdiretórios. Ela explica como coletamos, protegemos e divulgamos as
              informações que resultam do seu uso do nosso Serviço.
            </p>
            <p>
              Nós usamos seus dados para fornecer e melhorar o Serviço. Ao utilizar o Serviço, você concorda com a
              coleta e uso de informações de acordo com esta política. Salvo definição em contrário nesta Política de
              Privacidade, os termos utilizados nesta Política de Privacidade têm os mesmos significados que nos nossos{" "}
              <Link to="/pt/terms-of-use/" className="jd-terms-link">
                Termos de Uso
              </Link>
              .
            </p>
            <p>
              Nossos Termos de Uso ("<b>Termos</b>" ou "<b>Termos de Serviço</b>") regem todo o uso do nosso Serviço, e
              juntamente com a Política de Privacidade constitui o seu acordo conosco ("<b>Contrato</b>").
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Definições</h2>
            <p>
              <b>SERVIÇO</b> significa o site https://jubarte.design, todos os seus subdomínios e subdiretórios operados
              pela Jubarte Design.
            </p>
            <p>
              <b>DADOS PESSOAIS</b> são dados sobre um indivíduo vivo que pode ser identificado a partir desses dados
              (ou a partir destas e outras informações que estejam em nossa posse ou que possam vir a estar em nossa
              posse).
            </p>
            <p>
              <b>DADOS DE USO E LOGS</b> são dados coletados automaticamente ou gerados pelo uso do Serviço ou da
              própria infraestrutura do Serviço (por exemplo, a duração de uma visita a uma página).
            </p>
            <p>
              <b>DADOS DE CONTEÚDO</b> são dados produzidos ou fornecidos voluntariamente por você, por nós, ou por
              terceiros durante a utilização do Serviço.
            </p>
            <p>
              <b>COOKIES</b> são pequenos arquivos armazenados em seu dispositivo (computador ou dispositivo móvel).
            </p>
            <p>
              <b>DADOS DE MARKETING</b> são dados que podemos coletar durante pesquisas, concursos, eventos ou para
              enriquecer informações relacionadas a negócios sobre organizações.
            </p>
            <p>
              <b>CONTROLADOR DE DADOS</b> significa uma pessoa física ou jurídica que (sozinha ou em conjunto ou em
              comum com outras pessoas) determina as finalidades para as quais e como quaisquer dados pessoais são ou
              serão processados.
            </p>
            <p>
              <b>PROCESSADORES DE DADOS</b> (ou <b>FORNECEDORES DE SERVIÇOS</b>) significa qualquer pessoa física ou
              jurídica que processe os dados em nome do Controlador de Dados. Podemos utilizar os serviços de vários
              Prestadores de Serviços para processar seus dados de forma mais eficaz.
            </p>
            <p>
              <b>SUJEITO DOS DADOS PESSOAIS</b> é qualquer pessoa viva que seja objeto de Dados Pessoais.
            </p>
            <p>
              <b>O USUÁRIO</b> é o indivíduo que utiliza o nosso Serviço. O Usuário corresponde ao Sujeito dos Dados,
              que é o Sujeito dos Dados Pessoais.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Coleta e Utilização das Informações</h2>
            <p>
              Nós coletamos vários tipos diferentes de informações para diversos fins a fim de fornecer e melhorar nosso
              Serviço para você.
            </p>
            <p>
              Para esta Política de Privacidade, podemos ter ambas as funções de Controlador de Dados e Processador de
              Dados em relação aos seus dados.
            </p>
            <p>
              Atuamos como Controlador de Dados quando determinamos as finalidades e os meios de processamento de seus
              Dados Pessoais. Como Processador de Dados, nós processamos Dados Pessoais em nome do Controlador de Dados.
            </p>
            <p>
              De acordo com a lei aplicável, você também pode se tornar um Controlador de Dados e/ou Processador de
              Dados, e isso irá impor obrigações adicionais a você.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Tipos de Dados Coletados</h2>
            <p>
              <b>Dados Pessoais</b>
            </p>
            <p>
              Ao utilizar nosso Serviço, podemos solicitar que você nos forneça certas informações de identificação
              pessoal que podem ser usadas para contatá-lo ou identificá-lo ("<b>Dados Pessoais</b>"). As informações de
              identificação pessoal podem incluir, mas não se limitam a:
            </p>
            <p className="jd-terms-list-item">(a) Endereço de e-mail;</p>
            <p className="jd-terms-list-item">(b) Nome e sobrenome;</p>
            <p className="jd-terms-list-item">(c) Nome da organização;</p>
            <p className="jd-terms-list-item">(d) Cookies e Dados de Uso;</p>
            <p className="jd-terms-list-item">(e) Foto do Perfil.</p>
            <p>
              Podemos utilizar seus Dados Pessoais para contatá-lo com newsletters, materiais de marketing ou
              promocionais e outras informações que possam ser de seu interesse. Você pode optar por não receber nenhuma
              ou todas estas comunicações da nossa parte, seguindo o link de cancelamento em cada e-mail, alterando suas
              preferências de e-mail na página de gerenciamento de sua conta ou enviando-nos um{" "}
              <a
                href="mailto:contact@jubarte.design?subject=Cancelar Comunicações por E-mail"
                className="jd-terms-mailto"
              >
                e-mail
              </a>
              .
            </p>
            <p>
              <b>Dados de Uso e Registro</b>
            </p>
            <p>
              Também podemos coletar informações que seu navegador envia sempre que você visita nosso Serviço ou acessa
              o mesmo usando um dispositivo móvel ("<b>Dados de Uso e Registro</b>").
            </p>
            <p>
              Esses Dados de Uso e Registro podem incluir informações como métricas de uso (incluindo taxas de uso,
              frequência de logins, ocorrências de erros técnicos, relatórios de diagnóstico, preferências de
              configurações, informações de backup, reprodução de sessão e movimentos do mouse), o endereço do Protocolo
              de Internet do seu dispositivo (por exemplo Endereço IP), tipo de navegador, versão do navegador, agente
              do usuário, sistema operacional, idioma, configurações de fuso horário, identificadores de publicidade, as
              páginas do nosso Serviço que você visita, a hora e data da sua visita, o tempo gasto nessas páginas,
              tempos de resposta das páginas, interação entre páginas, identificadores únicos do dispositivo, a região
              em que você está localizado quando está logando e usando os Serviços, e outros dados de diagnóstico.
            </p>
            <p>
              Quando você acessa o Serviço com um dispositivo móvel, esses Dados de Uso e de Registro também podem
              incluir informações como o tipo de dispositivo móvel que você usa, a identificação única do dispositivo
              móvel, seu sistema operacional móvel, o tipo de navegador de Internet móvel que você usa, identificadores
              únicos do dispositivo e outros dados de diagnóstico.
            </p>
            <p>
              <b>Dados de Conteúdo</b>
            </p>
            <p>
              Nós podemos coletar dados que você voluntariamente fornece através do Serviço, e-mails, formulários
              online, formulários de pedido e registro, pesquisas, participação em promoções, ligações telefônicas e
              chats online com nosso serviço ao cliente e outros meios.
            </p>
            <p>
              Os dados de conteúdo podem incluir pedidos de design que você cria e atualizações da nossa equipe,
              mensagens, comentários, imagens, pesquisas, perguntas de suporte ao cliente, problemas e feedback geral
              que você optar por fornecer.
            </p>
            <p>
              <b>Dados de Cookies de Rastreamento</b>
            </p>
            <p>
              Utilizamos cookies e tecnologias similares de rastreamento para rastrear a atividade de nossos serviços e
              mantemos certas informações.
            </p>
            <p>
              Os cookies são arquivos com uma pequena quantidade de dados, que podem incluir um identificador anônimo
              único. Os cookies são enviados ao seu navegador a partir de um website e armazenados no seu dispositivo.
              Outras tecnologias de rastreamento também podem ser utilizadas, como beacons, tags e scripts para coletar
              e rastrear informações e para melhorar e analisar nosso Serviço.
            </p>
            <p>
              Você pode instruir seu navegador a recusar todos os cookies ou a indicar quando um cookie está sendo
              enviado. Entretanto, se você não aceitar cookies, você pode não ser capaz de usar algumas partes do nosso
              Serviço.
            </p>
            <p>Exemplos de Cookies que utilizamos:</p>
            <p className="jd-terms-list-item">
              (a) <b>Cookies de Sessão</b>: Utilizamos os Cookies de Sessão para operar nosso Serviço;
            </p>
            <p className="jd-terms-list-item">
              (b) <b>Cookies de Preferência:</b> Utilizamos Cookies de Preferência para lembrar suas preferências e
              várias configurações;
            </p>
            <p className="jd-terms-list-item">
              (c) <b>Cookies de Segurança</b>: Utilizamos os Cookies de Segurança para fins de segurança;
            </p>
            <p className="jd-terms-list-item">
              (d) <b>Cookies de Publicidade</b>: Os Cookies de Publicidade são usados para servir-lhe com anúncios que
              podem ser relevantes para você e seus interesses e para personalizar sua experiência.
            </p>
            <p>
              <b>Dados de Marketing</b>
            </p>
            <p>
              Se você optar por participar de nossas pesquisas, concursos, eventos (como webinars e eventos
              presenciais), ou aqueles nos quais somos afiliados, ou solicitar informações sobre nossos serviços,
              podemos coletar informações sobre você relacionadas à pesquisa, concurso ou evento como suas informações
              de contato, seu nome, endereço de e-mail, número de telefone, nome e endereço da organização e informações
              gerais sobre sua organização que você optar por fornecer, como receita anual da organização, número de
              funcionários e indústria.
            </p>
            <p>
              Utilizamos ferramentas padrão da Internet, tais como pixel tags, action tags, web beacons, tokens, git
              tags ou tecnologias similares em conexão com serviços e mensagens de e-mail em formatos específicos para,
              entre outras coisas, entender as ações dos Usuários e destinatários de e-mail, para determinar o sucesso
              de campanhas publicitárias ou promocionais e para compilar estatísticas sobre o uso dos serviços e taxas
              de resposta.
            </p>
            <p>
              Também podemos utilizar prestadores de serviços para obter informações adicionais relacionadas aos
              negócios de sua organização, tais como nome legal, tamanho e receita disponível publicamente, para nos
              ajudar a oferecer serviços adequados às necessidades de sua organização.
            </p>
            <p>
              Além disso, podemos coletar informações sobre taxas de abertura e clique de e-mails, incluindo se
              indivíduos clicaram em links, e quais páginas da web são visitadas após a abertura do e-mail.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Uso de Dados</h2>
            <p>A Jubarte Design utiliza os dados coletados para diversos fins:</p>
            <p className="jd-terms-list-item">(a) para fornecer e manter nosso Serviço;</p>
            <p className="jd-terms-list-item">(b) para notificá-lo sobre alterações em nosso Serviço; </p>
            <p className="jd-terms-list-item">
              (c) para permitir que você participe de recursos interativos do nosso Serviço quando você optar por
              fazê-lo;
            </p>
            <p className="jd-terms-list-item">(d) para fornecer suporte ao cliente;</p>
            <p className="jd-terms-list-item">
              (e) para coletar análises ou informações valiosas para que possamos melhorar nosso Serviço;
            </p>
            <p className="jd-terms-list-item">(f) para monitorar o uso do nosso Serviço;</p>
            <p className="jd-terms-list-item">(g) para prevenir fraudes e abusos;</p>
            <p className="jd-terms-list-item">(h) para detectar, prevenir e resolver problemas técnicos;</p>
            <p className="jd-terms-list-item">(i) para fins de pesquisa e desenvolvimento interno;</p>
            <p className="jd-terms-list-item">(j) para cumprir qualquer outro propósito para o qual você o forneça;</p>
            <p className="jd-terms-list-item">
              (k) para cumprir nossas obrigações e fazer valer nossos direitos decorrentes de quaisquer contratos
              firmados entre você e nós, inclusive para faturamento e cobrança;
            </p>
            <p className="jd-terms-list-item">
              (l) para fornecer a você avisos sobre sua conta, assinatura ou ambos, incluindo avisos de vencimento e
              renovação, instruções de e-mail, e assim por diante;
            </p>
            <p className="jd-terms-list-item">
              (m) para lhe fornecer notícias, ofertas especiais e informações gerais sobre outros bens, serviços e
              eventos que oferecemos que sejam similares àqueles que você já comprou ou consultou, a menos que você
              tenha optado por não receber tais informações;
            </p>
            <p className="jd-terms-list-item">
              (n) para cumprir a lei e nossas obrigações legais, inclusive para responder a um pedido ou ordem de um
              tribunal, órgão regulador ou autoridade governamental;
            </p>
            <p className="jd-terms-list-item">
              (o) de qualquer outra forma que possamos descrever quando você fornecer as informações;
            </p>
            <p className="jd-terms-list-item">(p) para qualquer outro propósito, com o seu consentimento.</p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Retenção de Dados</h2>
            <p>
              Nós faremos a retenção de seus Dados Pessoais somente pelo tempo necessário para os fins estabelecidos
              nesta Política de Privacidade. Manteremos e utilizaremos seus Dados Pessoais na medida do necessário para
              cumprir com nossas obrigações legais (por exemplo, se formos obrigados a reter seus dados para cumprir as
              leis aplicáveis), resolver disputas, e fazer cumprir nossos acordos e políticas legais.
            </p>
            <p>
              Nós também faremos a retenção de Dados de Uso para fins de análise interna. Os Dados de Uso são geralmente
              retidos por um período mais curto, exceto quando esses dados são usados para reforçar a segurança ou
              melhorar a funcionalidade do nosso Serviço, ou somos legalmente obrigados a reter esses dados por períodos
              mais longos.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Transferência de Dados</h2>
            <p>
              Suas informações, incluindo Dados Pessoais, podem ser transferidas para - e mantidas em - computadores
              localizados fora de seu estado, província, país ou outra jurisdição governamental onde as leis de proteção
              de dados podem diferir das leis de sua jurisdição.
            </p>
            <p>
              Se você estiver localizado fora do Canadá ou dos Estados Unidos e optar por nos fornecer informações,
              observe que transferimos os dados, incluindo Dados Pessoais, para o Canadá e para os Estados Unidos e os
              processamos lá.
            </p>
            <p>
              O seu consentimento a esta Política de Privacidade seguida pelo seu envio de tais informações representa a
              sua concordância com essa transferência.
            </p>
            <p>
              De acordo com o Regulamento Geral de Proteção de Dados Europeu ("<b>GDPR</b>" na sigla em inglês) e outras
              leis de proteção de dados, informações sobre você só podem ser transferidas de sua região para outras
              regiões se requisitos específicos forem atendidos. Por exemplo, sob a GDPR, informações sobre você podem
              ser transferidas do Espaço Econômico Europeu ("<b>EEE</b>") para fora do EEE se houver proteção de dados
              adequada. A sede da Jubarte Design no Canadá gerencia nosso Serviço. Como a Comissão Europeia considera o
              Canadá como um país que fornece proteção de dados adequada, as informações sobre você podem ser
              transferidas da EEE para o Canadá.
            </p>
            <p>
              Também utilizamos provedores de serviços de terceiros, tais como provedores de hospedagem gerenciada,
              processadores de cartão de crédito e parceiros de tecnologia, para fornecer o software, rede,
              infra-estrutura e outros serviços necessários para operar o Serviço. Esses provedores terceirizados podem
              processar ou armazenar dados pessoais em servidores fora da AAE e Suíça, inclusive no Canadá ou nos
              Estados Unidos. Nós confiamos na adequação (se enviado para o Canadá), no registro do prestador do serviço
              no Privacy Shield UE-EUA e no Swiss-US Privacy Shield (se enviado para os Estados Unidos), e/ou em
              cláusulas contratuais padrão (se enviado para os Estados Unidos ou encaminhado para outros países) para
              garantir que as informações sobre você sejam legalmente transferidas sob a legislação da União Europeia.
            </p>
            <p>
              A Jubarte Design tomará todas as medidas razoavelmente necessárias para garantir que seus dados sejam
              tratados com segurança e de acordo com esta Política de Privacidade e nenhuma transferência de seus Dados
              Pessoais ocorrerá para uma organização ou país a menos que haja controles adequados, incluindo a segurança
              de seus dados e outras informações pessoais.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Divulgação de Dados</h2>
            <p>Podemos divulgar informações pessoais que coletamos, ou que você fornece:</p>
            <p className="jd-terms-list-item">
              <b>(a) Divulgação para a aplicação da lei:</b>
            </p>
            <p className="jd-terms-list-item">
              Sob certas circunstâncias, podemos ser obrigados a divulgar seus dados pessoais se exigido por lei, para
              cumprir com processos legais, em resposta a pedidos válidos das autoridades públicas de qualquer país em
              que operamos, incluindo fora do seu país de residência.
            </p>
            <p className="jd-terms-list-item">
              <b>(b) Transação de Negócios:</b>
            </p>
            <p className="jd-terms-list-item">
              Se nossas subsidiárias ou nós estivermos envolvidos em uma fusão, aquisição, joint venture, cessão,
              transferência ou outra alienação de toda ou qualquer parte de nossos negócios, ativos ou ações (incluindo,
              sem limitação, em conexão com qualquer processo de falência ou similar) ou venda de ativos, todos e
              qualquer um de seus Dados Pessoais poderão ser transferidos.
            </p>
            <p className="jd-terms-list-item">
              <b>(c) Outros casos. Podemos divulgar suas informações também:</b>
            </p>
            <p className="jd-terms-list-subitem">(i) às nossas subsidiárias e afiliadas;</p>
            <p className="jd-terms-list-subitem">
              (ii) a contratados, prestadores de serviços, parceiros estratégicos, e outros terceiros que utilizamos
              para suportar nossos negócios;
            </p>
            <p className="jd-terms-list-subitem">(iii) para cumprir a finalidade para a qual você os fornece;</p>
            <p className="jd-terms-list-subitem">
              (iv) para proteger os direitos, privacidade, segurança ou propriedade, nossas, de nossas entidades
              afiliadas, suas, ou de outros;
            </p>
            <p className="jd-terms-list-subitem">
              (v) para proteger nossas operações ou as de quaisquer entidades afiliadas;
            </p>
            <p className="jd-terms-list-subitem">(vi) para incluir o logotipo da sua organização em nosso website;</p>
            <p className="jd-terms-list-subitem">
              (vii) para qualquer outro propósito divulgado por nós quando você fornecer as informações;
            </p>
            <p className="jd-terms-list-subitem">(vii) com o seu consentimento em quaisquer outros casos.</p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Segurança dos Dados</h2>
            <p>
              A segurança dos seus dados é fundamental para nós, mas lembre-se que nenhum método de transmissão pela
              Internet ou método de armazenamento eletrônico é 100% seguro. Enquanto nos esforçamos para usar meios
              comercialmente aceitáveis para proteger seus Dados Pessoais, não podemos garantir sua absoluta segurança.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">
              Seus Direitos de Proteção de Dados sob o Regulamento Geral de Proteção de Dados (GDPR)
            </h2>
            <p>
              Se você é residente da União Européia (UE) e do Espaço Econômico Europeu (EEE), você tem certos direitos
              de proteção de dados cobertos pela GDPR. Veja mais em{" "}
              <a
                href="https://eur-lex.europa.eu/eli/reg/2016/679/oj"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://eur-lex.europa.eu/eli/reg/2016/679/oj
              </a>
              .
            </p>
            <p>
              Nosso objetivo é tomar medidas razoáveis para permitir que você corrija, altere, exclua ou limite o uso de
              seus Dados Pessoais.
            </p>
            <p>
              Se você deseja ser informado sobre os Dados Pessoais que temos sobre você e se você deseja que eles sejam
              removidos de nossos sistemas, por favor nos envie um{" "}
              <a href="mailto:contact@jubarte.design?subject=Pesquisa de Dados Pessoais" className="jd-terms-mailto">
                e-mail
              </a>
              .
            </p>
            <p>Em determinadas circunstâncias, você tem os seguintes direitos de proteção de dados:</p>
            <p className="jd-terms-list-item">
              (a) o direito de acessar, atualizar ou apagar as informações que temos sobre você;
            </p>
            <p className="jd-terms-list-item">
              (b) o direito de retificação. Você tem o direito de ter suas informações retificadas se essas informações
              estiverem imprecisas ou incompletas;
            </p>
            <p className="jd-terms-list-item">
              (c) o direito de objeção. Você tem o direito de objeção ao nosso processamento de seus Dados Pessoais;
            </p>
            <p className="jd-terms-list-item">
              (d) o direito de restrição. Você tem o direito de solicitar que nós façamos a restrição do processamento
              de suas informações pessoais;
            </p>
            <p className="jd-terms-list-item">
              (e) o direito à portabilidade dos dados. Você tem o direito de receber uma cópia de seus Dados Pessoais em
              formato estruturado, legível por máquina e de uso comum;
            </p>
            <p className="jd-terms-list-item">
              (f) o direito de retirar o consentimento. Você também tem o direito de retirar seu consentimento a
              qualquer momento em que dependemos de seu consentimento para processar suas informações pessoais.
            </p>
            <p>
              Por favor, note que podemos pedir-lhe que verifique a sua identidade antes de responder a tais pedidos.
              Por favor, note também que talvez não possamos fornecer o serviço sem alguns dados necessários.
            </p>
            <p>
              Você tem o direito de reclamar a uma Autoridade de Proteção de Dados sobre a nossa coleta e uso de seus
              Dados Pessoais. Para obter mais informações, entre em contato com a autoridade local de proteção de dados
              no Espaço Econômico Europeu (EEE).
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">
              Seus Direitos de Proteção de Dados de acordo com a Lei de Proteção de Privacidade da Califórnia (CalOPPA)
            </h2>
            <p>
              A Lei de Proteção de Privacidade da Califórnia ("<b>CalOPPA</b>" na sigla em inglês) é a primeira lei
              estadual nos Estados Unidos a exigir que websites comerciais e serviços online publiquem uma política de
              privacidade. O alcance da lei vai muito além da Califórnia para exigir que uma pessoa ou empresa nos
              Estados Unidos (e concebível no mundo) que opera websites que coletam informações pessoalmente
              identificáveis de consumidores da Califórnia poste uma política de privacidade conspícua em seu site
              declarando precisamente os dados que estão sendo coletados e aqueles indivíduos com quem estão sendo
              compartilhados e para cumprir com esta política. Veja mais em{" "}
              <a
                href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
              </a>
            </p>
            <p>De acordo com a CalOPPA, nós concordamos com o seguinte:</p>
            <p className="jd-terms-list-item">(a) os usuários podem visitar nosso site de forma anônima;</p>
            <p className="jd-terms-list-item">
              (b) nosso link da Política de Privacidade inclui a palavra "Privacidade", e pode ser facilmente encontrado
              na página especificada acima na home page do nosso site;
            </p>
            <p className="jd-terms-list-item">
              (c) nós notificaremos nossos usuários sobre qualquer mudança de política de privacidade em nossa página de
              Política de Privacidade;
            </p>
            <p className="jd-terms-list-item">
              (d) os usuários podem alterar suas informações pessoais, enviando-nos um{" "}
              <a
                href="mailto:contact@jubarte.design?subject=Solicitação de atualização de Dados Pessoais"
                className="jd-terms-mailto"
              >
                e-mail
              </a>
              .
            </p>
            <p>
              <b>Nossa Política para Sinais de "Não Rastrear":</b>
            </p>
            <p>
              Nós não respondemos aos sinais de "Não Rastrear" ("<b>DNT</b>" na sigla em inglês) neste momento. Algumas
              ferramentas de terceiros podem rastrear suas ações quando você estiver navegando e acessando conteúdo.
            </p>
            <p>
              O sinal de Não Rastrear é uma preferência que você pode definir no seu navegador para informar aos sites
              que você não quer ser rastreado. Você pode ativar ou desativar o sinal de Não Rastrear visitando a página
              de Preferências ou Configurações do seu navegador da web.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Prestadores de Serviços</h2>
            <p>
              Nós podemos empregar empresas e indivíduos para facilitar nosso Serviço ("<b>Prestadores de Serviço</b>"),
              prestar Serviço em nosso nome, executar serviços relacionados ao Serviço ou nos auxiliar na análise de
              como nosso Serviço é utilizado.
            </p>
            <p>
              Esses terceiros têm acesso aos seus Dados Pessoais somente para realizar essas tarefas em nosso nome. Eles
              são obrigados a não divulgá-los ou usá-los para qualquer outra finalidade.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Análise de Dados (Analytics)</h2>
            <p>
              Podemos utilizar prestadores de serviços de terceiros para monitorar e analisar o uso do nosso Serviço.
            </p>
            <p>
              <b>Google Analytics</b>
            </p>
            <p>
              O Google Analytics é um serviço de análise web oferecido pela Google LLC que rastreia e reporta o tráfego
              do site. O Google utiliza os dados coletados para rastrear e monitorar o uso do nosso Serviço. Estes dados
              são compartilhados com outros serviços do Google. O Google pode utilizar os dados coletados para
              contextualizar e personalizar os anúncios da sua rede de publicidade.
            </p>
            <p>
              O Google Analytics neste Serviço pode utilizar a publicidade baseada em interesses do Google, dados de
              audiências de terceiros e informações do DoubleClick Cookie para ampliar a análise com dados demográficos,
              de interesses e de interação dos anúncios.
            </p>
            <p>
              Para obter mais informações sobre as práticas de privacidade do Google, visite a página dos Termos de
              Privacidade do Google{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://policies.google.com/privacy?hl=en
              </a>
              .
            </p>
            <p>
              Nós também encorajamos você a rever a política do Google para proteção dos seus dados:{" "}
              <a
                href="https://support.google.com/analytics/answer/6004245"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://support.google.com/analytics/answer/6004245
              </a>
              .
            </p>
            <p>
              <b>Rastreamento de conversão de anúncios do Google</b>
            </p>
            <p>
              O Rastreamento de conversão de anúncios do Google é um serviço analítico fornecido pelo Google LLC. Ele
              conecta os dados da rede de publicidade do Google Ads com as ações realizadas no Serviço.
            </p>
            <p>
              Para entender como funciona o rastreamento de conversão de anúncios do Google, por favor visite o guia do
              Google em{" "}
              <a
                href="https://support.google.com/google-ads/answer/1722022?hl=en"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://support.google.com/google-ads/answer/1722022?hl=en
              </a>
              .
            </p>
            <p>
              Para mais informações sobre as práticas de privacidade do Google, por favor visite a página dos Termos de
              Privacidade do Google:{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://policies.google.com/privacy?hl=en
              </a>
              .
            </p>
            <p>
              <b>Amplitude</b>
            </p>
            <p>
              Amplitude é um serviço analítico prestado pela Amplitude, Inc que rastreia e reporta o tráfego do site.
            </p>
            <p>
              Para mais informações sobre as práticas de privacidade da Amplitude, visite a página da Amplitude Privacy
              & Terms:{" "}
              <a
                href="https://amplitude.com/privacy"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://amplitude.com/privacy
              </a>
              .
            </p>
            <p>
              <b>Rastreamento de conversão de anúncios do Facebook (pixel do Facebook)</b>
            </p>
            <p>
              O Rastreamento de conversão de anúncios do Facebook (Facebook pixel) é um serviço analítico fornecido pelo
              Facebook, Inc. que conecta dados da rede de publicidade do Facebook com ações realizadas neste Serviço. O
              Facebook pixel rastreia conversões que podem ser atribuídas a anúncios no Facebook, Instagram e Audience
              Network.
            </p>
            <p>
              Para mais informações sobre as práticas de privacidade do Facebook, por favor, reveja a Política de Dados
              do Facebook em{" "}
              <a
                href="https://www.facebook.com/about/privacy/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.facebook.com/about/privacy/
              </a>
              .
            </p>
            <p>
              <b>Rastreamento das conversões no LinkedIn</b>
            </p>
            <p>
              O rastreamento de conversão do LinkedIn é um serviço analítico prestado pela LinkedIn Corporation que
              conecta dados da rede de publicidade do LinkedIn com ações realizadas no Serviço.
            </p>
            <p>
              Para entender como funciona o rastreamento de conversões e análise de sites do LinkedIn, por favor, revise
              os Termos para Rastreamento de Conversões, Análises e Ajuste de Audiência do LinkedIn em{" "}
              <a
                href="https://www.linkedin.com/legal/conversion-tracking"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.linkedin.com/legal/conversion-tracking
              </a>
              .
            </p>
            <p>
              Para maiores informações sobre as práticas de privacidade do LinkedIn Corporation, recomendamos que você
              reveja a Política de Privacidade do LinkedIn em{" "}
              <a
                href="https://www.linkedin.com/legal/privacy-policy"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.linkedin.com/legal/privacy-policy
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">
              Ferramentas de Integração Contínua, Entrega Contínua e Desenvolvimento de Software
            </h2>
            <p>
              Podemos utilizar Prestadores de Serviços de terceiros para automatizar o processo de desenvolvimento do
              nosso Serviço.
            </p>
            <p>
              <b>Netlify</b>
            </p>
            <p>
              Usamos a Netlify para hospedar alguns de nossos websites usados para entregar o Serviço para você. Netlify
              é um serviço prestado pela Netlify, Inc..
            </p>
            <p>
              Você pode encontrar mais informações sobre as políticas de privacidade da Netlify aqui:{" "}
              <a
                href="https://www.netlify.com/privacy/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.netlify.com/privacy/
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Remarcação Comportamental</h2>
            <p>
              A Jubarte Design utiliza serviços de remarcação para anunciar em sites de terceiros após a sua visita ao
              nosso Serviço. Nossos fornecedores terceirizados e nós usamos cookies para informar, otimizar e veicular
              anúncios com base em suas visitas passadas ao nosso Serviço.
            </p>
            <p>
              Além de qualquer opt-out oferecido por qualquer um dos serviços abaixo, o Usuário pode optar por não
              utilizar cookies em um serviço de terceiros visitando a página de opt-out da Network Advertising
              Initiative em{" "}
              <a
                href="https://optout.networkadvertising.org/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://optout.networkadvertising.org/
              </a>
              .
            </p>
            <p>
              <b>Anúncios do Google (AdWords)</b>
            </p>
            <p>O Google LLC fornece o serviço de remarcação de anúncios do Google (AdWords).</p>
            <p>
              Você pode optar por não participar do Google Analytics para Propagandas de Display e personalizar os
              anúncios da Rede de Display do Google visitando a página Configurações de Anúncios do Google:{" "}
              <a
                href="https://www.google.com/settings/ads"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.google.com/settings/ads
              </a>
              .
            </p>
            <p>
              O Google também recomenda a instalação do Google Analytics Opt-out Browser Add-on -{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://tools.google.com/dlpage/gaoptout
              </a>{" "}
              - para o seu navegador da web. O Google Analytics Opt-out Browser Add-on oferece aos visitantes a
              capacidade de evitar que seus dados sejam coletados e usados pelo Google Analytics.
            </p>
            <p>
              Para obter mais informações sobre as práticas de privacidade do Google, visite a página dos Termos de
              Privacidade do Google:{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://policies.google.com/privacy?hl=en
              </a>
              .
            </p>
            <p>
              <b>Audiência Personalizada do Facebook e Remarketing do Facebook</b>
            </p>
            <p>
              Utilizamos a Audiência Personalizada do Facebook e Remarketing do Facebook, que são serviços de
              remarketing e direcionamento comportamental fornecidos pela Facebook Inc que conecta a atividade deste
              Serviço com a rede de publicidade do Facebook.
            </p>
            <p>
              Você pode saber mais sobre publicidade baseada em interesses do Facebook visitando esta página:{" "}
              <a
                href="https://www.facebook.com/help/164968693837950"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.facebook.com/help/164968693837950
              </a>
              .
            </p>
            <p>
              Para optar por não participar dos anúncios baseados em interesses do Facebook, siga estas instruções do
              Facebook:{" "}
              <a
                href="https://www.facebook.com/help/568137493302217"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.facebook.com/help/568137493302217
              </a>
              .
            </p>
            <p>
              O Facebook adere aos Princípios auto-reguladores para publicidade comportamental on-line, estabelecidos
              pela Digital Advertising Alliance. Você também pode optar por não participar do Facebook e de outras
              empresas participantes por meio da Digital Advertising Alliance nos EUA{" "}
              <a
                href="https://www.aboutads.info/choices/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.aboutads.info/choices/
              </a>
              , da Digital Advertising Alliance of Canada{" "}
              <a href="https://youradchoices.ca/" target="_blank" rel="nofollow noopener noreferrer" className="jd-terms-link">
                https://youradchoices.ca/
              </a>{" "}
              ou da European Interactive Digital Advertising Alliance na Europa{" "}
              <a
                href="https://www.youronlinechoices.eu/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.youronlinechoices.eu/
              </a>
              , ou optar por não participar usando as configurações do seu dispositivo móvel.
            </p>
            <p>
              Para obter mais informações sobre as práticas de privacidade do Facebook, visite a Política de Dados do
              Facebook:{" "}
              <a
                href="https://www.facebook.com/privacy/explanation"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.facebook.com/privacy/explanation
              </a>
              .
            </p>
            <p>
              <b>Remarcação do site do LinkedIn</b>
            </p>
            <p>
              A Remarcação do website do LinkedIn é um serviço de remarketing e marcação comportamental prestado pelo
              LinkedIn Corporation que conecta a atividade deste Serviço com a rede de publicidade do LinkedIn.
            </p>
            <p>
              Você pode optar por não autorizar a Remarcação do site do LinkedIn, ajustando suas configurações de
              anúncios personalizados no seguinte site:{" "}
              <a
                href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
              </a>
              .
            </p>
            <p>
              Você pode saber mais sobre as práticas e políticas de privacidade do LinkedIn visitando a seguinte página
              de Política de Privacidade:{" "}
              <a
                href="https://www.linkedin.com/legal/privacy-policy"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://www.linkedin.com/legal/privacy-policy
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Gerenciamento de Tags</h2>
            <p>
              Este tipo de serviço ajuda a Jubarte Design a gerenciar as tags ou scripts necessários neste Serviço de
              forma centralizada, o que resulta no fluxo de Dados dos Usuários através destes serviços, resultando
              potencialmente na sua retenção.
            </p>
            <p>
              <b>Gerenciador de Tags do Google (Google Tag Manager)</b>
            </p>
            <p>O Gerenciador de Tags do Google é um serviço de gerenciamento de tags fornecido pela Google LLC.</p>
            <p>
              Nós o encorajamos a ler os Termos de Serviço do Google Tag Manager em{" "}
              <a
                href="https://support.google.com/tagmanager/answer/7157428"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://support.google.com/tagmanager/answer/7157428
              </a>
              .
            </p>
            <p>
              Para mais informações sobre as práticas de privacidade do Google, visite a página de Termos de Privacidade
              do Google:{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://policies.google.com/privacy?hl=en
              </a>
              .
            </p>
            <p>
              <b>Segment</b>
            </p>
            <p>O Segment é um serviço de gerenciamento de tags prestado pela Segment.io Inc..</p>
            <p>
              Você pode ler a Política de Privacidade da Segment aqui:{" "}
              <a
                href="https://segment.com/legal/privacy/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://segment.com/legal/privacy/
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Pagamentos</h2>
            <p>
              Podemos fornecer produtos pagos, serviços pagos ou ambos dentro do Serviço. Nesse caso, utilizamos
              serviços de terceiros para processamento de pagamentos (por exemplo, processadores de pagamentos).
            </p>
            <p>
              Nós não armazenaremos ou recolheremos os detalhes do seu cartão de pagamento. Essas informações são
              fornecidas diretamente aos nossos processadores de pagamento terceirizados cujo uso de suas informações
              pessoais é regido pela Política de Privacidade dos mesmos. Estes processadores de pagamento aderem aos
              padrões estabelecidos pelo PCI-DSS, conforme gerenciado pelo PCI Security Standards Council, que é um
              esforço conjunto de marcas como Visa, Mastercard, American Express e Discover. Os requisitos do PCI-DSS
              ajudam a garantir o tratamento seguro das informações de pagamento.
            </p>
            <p>Os processadores de pagamento com os quais trabalhamos são:</p>
            <p>
              <b>Stripe:</b>
            </p>
            <p>
              A Política de Privacidade do Stripe pode ser visualizada em{" "}
              <a
                href="https://stripe.com/us/privacy"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://stripe.com/us/privacy
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Ferramentas de Chat</h2>
            <p>
              Utilizamos a ferramenta de chat Gist fornecida pela ConvertFox Global, Inc para nos comunicarmos com você
              e para melhorar a experiência do Usuário.
            </p>
            <p>
              Nós usamos a Gist para enviar mensagens e e-mails para você e para ajudá-lo com problemas. Para fazer
              isso, nós enviamos as informações de contato que você forneceu durante o registro para a Gist, por
              exemplo, seu nome de usuário e seu endereço de e-mail. Enviamos ainda informações sobre o site que o
              enviou para o nosso site, produtos e serviços (URL de referência), seu navegador e sua localização. Estas
              informações serão armazenadas em servidores da Gist.
            </p>
            <p>
              Você pode encontrar mais informações de privacidade sobre a Gist aqui:{" "}
              <a
                href="https://getgist.com/privacy/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://getgist.com/privacy/
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Links para Outros Sites</h2>
            <p>
              Nosso Serviço pode conter links para outros sites que não são operados por nós. Se você clicar em um link
              de terceiros, você será direcionado para o site desses terceiros. Aconselhamos fortemente que você reveja
              a Política de Privacidade de cada site que visitar.
            </p>
            <p>
              Nós não temos controle e não assumimos responsabilidade pelo conteúdo, políticas de privacidade ou
              práticas de sites ou serviços de terceiros.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Privacidade de Crianças e Menores</h2>
            <p>
              Nossos Serviços não são destinados a menores de 18 anos ("<b>Menores</b>", "<b>Criança</b>" ou "
              <b>Crianças</b>").
            </p>
            <p>
              Nós não coletamos intencionalmente informações pessoais identificáveis de menores de 18 anos. Se você
              souber que uma Criança nos forneceu Dados Pessoais, por favor entre em contato conosco. Se tomarmos
              conhecimento de que coletamos Dados Pessoais de Crianças sem verificar o consentimento dos pais, tomamos
              medidas para remover essa informação de nossos servidores.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Alterações a esta Política de Privacidade</h2>
            <p>
              Podemos atualizar nossa Política de Privacidade de tempos em tempos. Nós o notificaremos de qualquer
              alteração, colocando a nova Política de Privacidade nesta página.
            </p>
            <p>
              Se tivermos o seu endereço de e-mail, nós o informaremos via e-mail e um aviso em destaque em nosso
              Serviço, antes que a mudança se torne efetiva e atualizaremos a "data efetiva" no topo desta Política de
              Privacidade.
            </p>
            <p>
              Você é aconselhado a rever esta Política de Privacidade periodicamente para quaisquer alterações. Mudanças
              nesta Política de Privacidade são efetivas quando são publicadas nesta página.
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Fale Conosco</h2>
            <p>Se você tiver alguma dúvida sobre esta Política de Privacidade, por favor, entre em contato conosco:</p>
            <p>
              Por{" "}
              <a
                href="mailto:contact@jubarte.design?subject=Questões sobre Política de Privacidade"
                className="jd-terms-mailto"
              >
                e-mail
              </a>
              .
            </p>
            <p>
              Através do chat em nosso website:{" "}
              <a href="https://jubarte.design" rel="nofollow" className="jd-terms-link">
                https://jubarte.design
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </article>
  </Layout>
)

export default PrivacyPolicyPage
